import coder53Logo from "../assets/img/coder53-logo.jpeg"
import ctcgLogo from "../assets/img/ctcg-logo.png"
import ciscoLogo from "../assets/img/cisco-logo.png"

let experienceTimeline = [
{
    id: 1,
    title: "Software Engineer @ Coder FiftyThree (Intern)",
    location: "Petaling Jaya, Malaysia",
    description:
      "At Coder FiftyThree, an IT consulting company, I was a Software Engineer intern initially working on a website using Angular and TypeScript. Then I was assigned to develop a mobile application using Xamarin and C# for an organisation of over 10,000 employees",
    date: "Jun 2021 - Sep 2021",
    icon: coder53Logo
  },
  {
    id: 2,
    title: "Application Apprentice @ CTC Global SG (Full Time)",
    location: "Singapore, Singapore",
    description:
      "At CTC Global Singapore, which is a fully owned subsidiary of ITOCHU Corporation. I joined in as an apprentice working on in the applications team. I was responsible of creating evaluating products and creating POC focusing on Red Hat products and AI/ML. By the end of it I was able to obtain expertise in Red Hat Ansible and OpenShift both of which I am officially certified in. With the rising popularity of LLM at that time, I successfully created an internal chatbot utilising RAG to query our internal data.",
    date: "Sep 2022 - Sep 2023",
    icon: ctcgLogo
  },
  {
    id: 3,
    title: "Project Engineer @ CTC Global SG (Full Time)",
    location: "Singapore, Singapore",
    description:
      "I was promoted to a Project Engineer at CTC Global Singapore, with much of my focus shifting towards creating AI/ML solutions. I have successfully deployed a production-ready chatbot internally, allowing employees query HR related matters. I am also providing support to an Ansible automation project, ensuring the environment is set up properly.",
    date: "Sep 2023 - Oct 2024",
    icon: ctcgLogo
  },
  {
    id: 4,
    title: "AI/ML/LLM Proof of Concept Engineer @ Cisco (Full Time)",
    location: "Singapore, Singapore",
    description:
      "At Cisco, I will be responsible for building AI use cases and proof of concepts that highlight the capabilities and versatility of Cisco products. The journey begins...",
    date: "Nov 2024 - Present",
    icon: ciscoLogo
  },
]

export default experienceTimeline;