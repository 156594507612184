import { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { ArrowRightCircle } from "react-bootstrap-icons"
import portraitImg from "../assets/img/self-portrait.jpeg"
import TextTransition, { presets } from 'react-text-transition';
import resumeFile from "../assets/resume/Lim Yen Kai CV.pdf"

export const Banner = () => {
    const toRotate = [ "ML Engineer", "Data Scientist", "DevOps Engineer", "Cloud Engineer" ];

    const [index, setIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(
        () => setIndex((index) => index + 1),
        3000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);

    return(
        <section className="banner" id="home">
            <Container>
                <Row className="align-item-center">
                    <Col className="introduction" xs={12} m={6} xl={7}>
                    <div>
                        <h1>I'm Yen Kai,</h1>
                        {/* <h1><span className="wrap">{text}</span></h1> */}
                        <h1>
                            <TextTransition className="text-transition" springConfig={presets.stiff} direction="down" >
                                {toRotate[index % toRotate.length]}
                            </TextTransition>
                        </h1>
                        <p>
                            Hi, my name is Lim Yen Kai, a 24 years old Software Engineer from Kuala Lumpur, Malaysia.
                            I'm a highly motivated Software Engineer with 2 years of experience. 
                            Being passionate in the field of Artificial Intelligence, 
                            I love reading up on the latest technologies on my free time. 
                            I'm driven and always hungry to acquire more skills that can bring value to the world.
                        </p>
                        <Col className="button-box">
                            <button onClick={() => window.open("https://www.linkedin.com/in/limyenkai/", "_blank")}>Let's connect <ArrowRightCircle size={25} /> </button>
                            <a href={resumeFile} target="_blank"> 
                                <button>Résumé <ArrowRightCircle size={25} /></button>
                            </a>
                        </Col>
                    </div>
                    </Col>
                    <Col xs={12} m={6} xl={5}>
                        <img className="portrait-image" src={portraitImg} alt="Kai Img" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}