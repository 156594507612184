import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { Container, Row } from "react-bootstrap";
import projectEntry from "../components/projectEntry"
import leftArrow from "../assets/img/left-arrow.png"
import rightArrow from "../assets/img/right-arrow.png"

export const Projects = () => {
    const [selected, setSelected] = React.useState([]);
    const isItemSelected = (id) => !!selected.find((el) => el === id);
    const handleClick =
        (id) =>
        ({ getItemById, scrollToItem }) => {
        const itemSelected = isItemSelected(id);

        setSelected((currentSelected) =>
            itemSelected
            ? currentSelected.filter((el) => el !== id)
            : currentSelected.concat(id),
        );
        };

    const Arrow = ({ onClick, disabled, className, children }) => (
        <button disabled={disabled} onClick={onClick} className={className}>
            {children}
        </button>
    );

    const LeftArrow = () => {
        const visibility = React.useContext(VisibilityContext);
        const isFirstItemVisible = visibility.useIsVisible('first', true);

        return (
          <Arrow
            disabled={isFirstItemVisible}
            onClick={() => visibility.scrollPrev()}
            className="left"
          >
            {/* <img className="arrow-button" src={leftArrow} alt='left-arrow'/> */}
            <img 
                className={`arrow-button ${isFirstItemVisible ? 'disabled' : ''}`} 
                src={leftArrow} 
                alt='left-arrow'
            />
          </Arrow>
        );
      };
        
    const RightArrow = () => {
        const visibility = React.useContext(VisibilityContext);
        const isLastItemVisible = visibility.useIsVisible('last', false);

        return (
            <Arrow
            disabled={isLastItemVisible}
            onClick={() => visibility.scrollNext()}
            className="right"
            >
            {/* <img className="arrow-button" src={rightArrow} alt='right-arrow'/> */}
                <img 
                    className={`arrow-button ${isLastItemVisible ? 'disabled' : ''}`} 
                    src={rightArrow} 
                    alt='right-arrow'
                />
            </Arrow>
        );
    };

    function Card({ onClick, selected, project }) {
        const visibility = React.useContext(VisibilityContext);
        // const visible = visibility.isItemVisible(project.id);

        return (
            <div
                onClick={() => onClick(visibility)}
                style={{ 
                    width: '700px',            
                    backgroundColor: '#FFFFFF',
                    minWidth: '300px',
                    maxWidth: '700px',
                    minHeight: '400px',
                    maxHeight: '600px',
                    alignItems: 'center',       
                    margin: '10px 10px',        
                    padding: '50px 20px',       
                    borderRadius: '20px',
                    }}
                tabIndex={0}
                className="card-container"
            >
                <div className="card">
                    {/* Project image */}
                    <div>
                        <img src={project.img} alt={project.title} className='card-img'/>
                    </div>

                    {/* Project details */}
                    <h5>{project.title}</h5>
                    <h6>{project.longDesc}</h6>

                    <div className='card-body'>
                        <div className='title'>
                            <p><strong>Client: </strong> </p>
                            <p><strong>Technologies: </strong></p>
                            <p><strong>URL: </strong></p>
                        </div>
                        <div className='description'>
                            <p>{project.client}</p>
                            <p>{project.technology}</p>

                            {/* Display URL or show Offline text */}
                            {project.url ? (
                                <a href={project.url} target="_blank" rel="noopener noreferrer">
                                    {project.url}
                                </a>
                            ) : (
                                <p style={{ color: 'red', fontWeight: 'bold' }}>Offline</p>
                            )}
                        </div>
                    </div>
                    
                    {/* Visibility and selection status */}
                    {/* <div>visible: {JSON.stringify(visible)}</div> //requires fix
                    <div>selected: {JSON.stringify(!!selected)}</div> */}
                </div>
            </div>
        );
    }
    
    return (
        <section className="projects" id="projects">
        <Container className="projects-box">
            <Row>
                <h2>Projects</h2>
                <p>Here are some of the projects that I have worked on.</p>
            </Row>
            <Row className="scroll-container">
                <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                {projectEntry.map((project) => (
                    <Card
                    project={project}
                    key={project.id}
                    itemId={project.id}
                    title={project.id}
                    onClick={handleClick(project.id)}
                    selected={isItemSelected(project.id)}
                    />
                ))}
                </ScrollMenu>
            </Row>
        </Container>
        </section>
      );

}