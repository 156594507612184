import logo from './logo.svg';
import './App.css';
import { NavBar } from './components/NavBar';
import { Banner } from './components/Banner';
import { Education } from './components/Education';
import { Skills } from './components/Skills';
import { Projects } from './components/Projects';
// import { Projects2 } from './components/test'
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import 'bootstrap/dist/css/bootstrap.min.css'
import { Experience } from './components/Experience';
import { Certificate } from './components/Certificate';
 
function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Education />
      <Skills />
      <Experience />
      <Projects />
      {/* <Projects2 /> */}
      <Certificate />
      <Contact />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
